import type {App} from "vue";
import type {AxiosRequestConfig, AxiosResponse} from "axios";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import {requestFailed} from "@/core/helpers/helpers";
import {useAuthStore} from "@/stores/auth";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        this.setInterceptor();
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common[
            "Authorization"
            ] = `Bearer ${JwtService.getToken()}`;
        ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
            "application/json";
    }

    /**
     * @description send the GET HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     * @param prefixType
     */
    public static query<T = any>(
        resource: string,
        params?: any,
        prefixType: string = "internal"
    ): Promise<AxiosResponse> {
        this.setPrefixApi(prefixType);

        return ApiService.vueInstance.axios.get<T>(resource, params);
    }

    /**
     * @description send the GET HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param slug
     * @param prefixType
     * @param options
     */
    public static get<T = any>(
        resource: string,
        slug = "" as string,
        prefixType: string = "internal",
        options: {} = {},
    ): Promise<AxiosResponse> {
        this.setPrefixApi(prefixType);

        return ApiService.vueInstance.axios.get<T>(`${resource}/${slug}`, options);
    }

    /**
     * @description set the POST HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     * @param prefixType
     */

    public static post(
        resource: string,
        params: any,
        prefixType: string = "internal"
    ): Promise<AxiosResponse> {
        this.setPrefixApi(prefixType);

        return ApiService.vueInstance.axios.post(`${resource}`, params);
    }

    /**
     * @description send the UPDATE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param slug
     * @param params
     * @param prefixType
     */
    public static update(
        resource: string,
        slug: string,
        params: any,
        prefixType: string = "internal"
    ): Promise<AxiosResponse> {
        this.setPrefixApi(prefixType);

        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     * @param prefixType
     */
    public static put(
        resource: string,
        params: any,
        prefixType: string = "internal"
    ): Promise<AxiosResponse> {
        this.setPrefixApi(prefixType);

        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    /**
     * @description Send the PATCH HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     * @param prefixType
     */
    public static patch(
        resource: string,
        params: any,
        prefixType: string = "internal"
    ): Promise<AxiosResponse> {
        this.setPrefixApi(prefixType);

        return ApiService.vueInstance.axios.patch(`${resource}`, params);
    }


    /**
     * @description Send the DELETE HTTP request
     * @returns Promise<AxiosResponse>
     * @param resource
     * @param params
     * @param prefixType
     */
    public static delete(
        resource: string,
        params: any = undefined,
        prefixType: string = "internal"
    ): Promise<AxiosResponse> {
        this.setPrefixApi(prefixType);

        return ApiService.vueInstance.axios.delete(`${resource}`, { data: params });
    }

    private static setPrefixApi(prefixType: string): void {
        if (prefixType === "root") {
            ApiService.vueInstance.axios.defaults.baseURL =
                import.meta.env.VITE_APP_API_URL + "/";
        }
        else if (prefixType === "public") {
            ApiService.vueInstance.axios.defaults.baseURL =
                import.meta.env.VITE_APP_API_URL + "/public";
        } else {
            ApiService.vueInstance.axios.defaults.baseURL =
                import.meta.env.VITE_APP_API_URL + "/internal";
        }
    }

    private static setInterceptor(): void {
        ApiService.vueInstance.axios.interceptors.response.use(
            (response) => {
                if (response.status >= 200 && response.status < 300) {
                    localStorage.removeItem('errors')
                    return Promise.resolve(response);
                } else {
                    return Promise.reject(response);
                }
            },
            (error) => {
                if (!error.response) {
                    router.replace({
                        path: "/500",
                    });
                } else if (error.response.status == 217) {
                    router.push({name: "217"});
                } else if (error.response.data.rc == "ERR_PROFILE_NOT_COMPLETE") {
                    localStorage.setItem('errors', JSON.stringify([
                      error.response.data.rc,
                    ]))

                    router.push({name: "biodata"});
                } else if (error.response.status == 401) {
                    const store = useAuthStore();
                    store.logout();
                    router.push({name: "sign-in"});
                } else if (error.response.status == 404) {
                    router.push({name: "404"});
                } else if (
                    error.response.status >= 402 &&
                    error.response.status < 500
                ) {
                    requestFailed(error);
                }

                return Promise.reject(error);
            }
        );
    }
}

export default ApiService;
